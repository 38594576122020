import React from 'react';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, '16 March, 2019', 'Training Event 1', 'Joelle', 34, 'Closed'),
  createData(1, '22 April, 2019', 'Training Event 2', 'Dianne', 23, 'Closed'),
  createData(2, '1 June, 2020', 'Training Event 3', 'Andrej', 35, 'Closed'),
  createData(3, '15 September, 2020', 'Training Event 4', 'Joelle', 40, 'Closed'),
  createData(4, '6 November, 2020', 'Training Event 5', 'Dianne', 12, 'Closed'),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

function formatDate(mongoDateFormat) {
  return mongoDateFormat.slice(0,10)
}

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      upcomingEvents: [],
      eventToggle: 1
    }
  }

  handleClickRow = (e) => {
    console.log('clicked row')
    console.log(e)
    this.setState({
      eventToggle: this.state.eventToggle + 1
    })
    this.props.updateEvent(e.target.getAttribute('data-event-id'))
  }

  updateEvent = (e) => {
    console.log('********')
    console.log(e.target.getAttribute('data-event-id'))
    console.log('********')
    this.setState({
      eventToggle: this.state.eventToggle + 1
    })
    this.props.updateEvent(e.target.getAttribute('data-event-id'))
  }

  componentDidMount() {
    axios.get('https://mcce.rasevic.io/api/v1/events')
          .then(response => {
            console.log(response)
            this.setState({
              upcomingEvents: response.data
            })
          })
          .catch(err => {
            console.log(err)
          })
  }

  render() {
    const { classes } = this.props;
    console.log(this.state)
    return (
      <React.Fragment>
        <Title>Recent Events</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              {/*need to make name a hyperlink to the actual event page  */}
              <TableCell>Name</TableCell>
              <TableCell>Lead Facilitator</TableCell>
              <TableCell>Registered Attendees</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.shipTo}</TableCell>
                <TableCell>{row.paymentMethod}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.seeMore}>
          <Link color="primary" href="#" onClick={preventDefault}>
            See more recent events
          </Link>
          <br />
          <br />
        </div>
        <Title>Upcoming Events</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Lead Facilitator</TableCell>
              <TableCell>Co-Facilitators ?</TableCell>
              <TableCell>Responsible Party</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.upcomingEvents.map((event) => (
              <TableRow key={event._id} onClick={this.handleClickRow}>
                <TableCell data-event-id={event._id}>{event.title}</TableCell>
                <TableCell data-event-id={event._id}>{formatDate(event.startDate)}</TableCell>
                <TableCell data-event-id={event._id}>{formatDate(event.stopDate)}</TableCell>
                <TableCell data-event-id={event._id}>{`${event.leadFacilitator}`}</TableCell>
                <TableCell data-event-id={event._id}>{`${event.hasCoFacilitators}`}</TableCell>
                <TableCell data-event-id={event._id}>{`${event.responsibleParty}`}</TableCell>
                <TableCell align="right">
                  <Link color="primary" href="#" data-event-id={event._id} onClick={this.updateEvent}>
                    {`Update ${event.title}`}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.seeMore}>
          <Link color="primary" href="#" onClick={preventDefault}>
            See more upcoming events
          </Link>
        </div>
      </React.Fragment>
    )
  }     
}

export default withStyles(useStyles)(Events);