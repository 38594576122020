import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

function formatDate(mongoDateFormat) {
    return mongoDateFormat.slice(0,10)
  }

const Course = (props) => {
    console.log('------------')
    console.log(props)
    console.log('***********')
    return(
        <div>
            { props.course ? (   
                <Card style={{margin: "10px"}}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                            {props.course.title}
                        </Typography>
                        <Typography component="p">
                            {props.course.eventType}
                        </Typography>
                        <Typography component="p">
                            Start Date: {formatDate(props.course.startDate)}
                        </Typography>
                        <Typography component="p">
                            End Date: {formatDate(props.course.stopDate)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="primary" href="#" target="_blank">
                            Go To Event
                        </Button>
                    </CardActions>
                </Card>
            ) : null}
        </div>
    )
}
export default Course