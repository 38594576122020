import React, { Component } from 'react'


class NewEvent extends Component {
  constructor(props) {
    super(props)
   
  }
 
  render() {
    return (
     <div>
         Create a New Event
     </div>
    );
   }
}

export default NewEvent;
