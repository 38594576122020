import React, { Component } from 'react'
import AdminDrawer from './AdminDrawer'

class AdminDashboard extends Component {
  constructor(props) {
    super(props)
  }
 
  render() {
    return (
      <div>
        <AdminDrawer />
      </div>
    );
  }
}

export default AdminDashboard;
