import React from 'react';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import 'date-fns';
import {
    Paper,
    Grid,
    TextField,
    Button,
    FormControlLabel,
    Checkbox
  } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import {
    Face,
    Fingerprint,
    Mail,
    Home,
    People,
    LocalPhone
  } from "@material-ui/icons";  
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ValidatorForm } from "react-material-ui-form-validator";
import Title from './Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

const eventTypes = [
  {value: 'workshop',
  label: 'workshop'
  },
  {value: 'summit',
  label: 'summit'
  },
  {value: 'webinar',
  label: 'webinar'
  },
  {value: 'development',
  label: 'development'
  },
  {value: 'other',
  label: 'other'
  },
]

const responsibleParties = [
  {value: 'Joelle',
  label: 'Joelle'
  },
  {value: 'Kim',
  label: 'Kim'
  }
]

const facilitators = [
  'Test1 Facilitator',
  'Test2 Facilitator',
  'Test3 Facilitator',
  'Test4 Facilitator',
  'Test5 Facilitator',
  'Test6 Facilitator',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? "fontWeightRegular"
        : "fontWeightMedium",
  };
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    margin: {
        margin: theme.spacing(2)
      },
    padding: {
      padding: theme.spacing(1)
    },
    title: {
      textAlign: "center"
    },
    label: {
      width: '100%'
    }
}));

class UpdateEvent extends React.Component{
  constructor(props) {
    super(props)
    console.log('inside constuctor to update')
    console.log(props)
    this.state = {
				eventId: props.eventId,
        title: "",
        eventType: "",
        responsibleParty: '',
        leadFacilitator: '',
        hasCoFacilitators: false,
        coFacilitators: [],
        techFirstName: '',
        techLastName: '',
        startDate: new Date(),
        stopDate: new Date(),
        startTime: new Date(),
        stopTime: new Date(),
        cdpCredits: 0,
        microCredentials: 0,
        totalHours: 0,
        submissionFinalDate: ''
      };
  }

  componentDidMount() {
		const eventId = this.state.eventId
    axios.get(`https://mcce.rasevic.io/api/v1/events/${eventId}`)
          .then(response => {
						console.log(response.data)
						const data = response.data
						const submissionFinalDate = data.workshop.finalSubmissionDate.slice(0,16)
						this.setState({
							title: data.title,
							eventType: data.eventType,
							responsibleParty: data.responsibleParty,
							leadFacilitator: data.leadFacilitator,
							hasCoFacilitators: data.hasCoFacilitators,
							coFacilitators: data.coFacilitators,
							startDate: data.startDate,
							stopDate: data.stopDate,
							startTime: data.startTime,
							stopTime: data.stopTime,
							cdpCredits: data.workshop.cdpCredits,
							microCredentials: data.workshop.microCredentials,
							totalHours: data.workshop.totalHours,
							submissionFinalDate: submissionFinalDate
						})
           
          })
          .catch(err => {
            console.log(err)
          })
  }

  handleSelectEventType = (e) => {
    console.log(e.target.value)
    this.setState({
      eventType: e.target.value
    })
  }

  handleSelectResponsibleParty = (e) => {
    console.log(e.target.value)
    this.setState({
      responsibleParty: e.target.value
    })
  }

  handleSelectLeadFacilitator = (e) => {
    console.log(e.target.value)
    this.setState({
      leadFacilitator: e.target.value
    })
  }

  handleHasCoFacilitators = (e) => {
		console.log(e.target.value)
		console.log(this.state)
    this.setState({
      hasCoFacilitators: !this.state.hasCoFacilitators
    })
  }

  handleChangeCoFacilitators = (e) => {
    console.log(e.target.value)
    this.setState({
      coFacilitators: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    console.log('clicked update')
    console.log(this.state)
    axios.put(`https://mcce.rasevic.io/api/v1/events/${this.props.eventId}`, this.state)
      .then(res => {
        console.log(res)
        console.log(res.data)
        this.props.updateEvent()
      })
      .catch(err => console.log(err))
  }

  handleTitleChange = e => {
    this.setState({
      title: e.target.value
    })
  }

  handleTechFirstNameChange = e => {
    this.setState({
      techFirstName: e.target.value
    })
  }
  handleTechLastNameChange = e => {
    this.setState({
      techLastName: e.target.value
    })
  }
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    })
  }
  handleStopDateChange = date => {
    this.setState({
      stopDate: date
    })
  }
  handleStartTimeChange = time => {
    console.log(time)
    this.setState({
      startTime: time
    })
  }
  handleStopTimeChange = time => {
    this.setState({
      stopTime: time
    })
  }
  handleCdpCreditsChange = e => {
    this.setState({
      cdpCredits: e.target.value
    })
  }
  handleMicroCreditsChange = e => {
    this.setState({
      microCredentials: e.target.value
    })
  }
  handleTotalHoursChange = e => {
    this.setState({
      totalHours: e.target.value
    })
  }
  handleFinalSubmissionChange = e => {
		console.log(e.target.value)
    let final = new Date(e.target.value)
    console.log(final)
    this.setState({
      submissionFinalDate: e.target.value
    })
  }

  render() {
    const { classes, theme } = this.props;
    const { title, eventType } = this.state;
    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        onError={errors => console.log(errors)}
      >
       
          <div className={classes.margin}>
            <Typography
              className={classes.title}
              component="h2"
              variant="display2"
              gutterBottom
            >
              Update Event: {this.state.title}
            </Typography>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid container item md={true} sm={true} xs={true}>
                <TextField
                  id="eventname"
                  label="Event Name"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleTitleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid container item required md={true} sm={true} xs={true}>
                <TextField
                  id="event-type-select"
                  select
                  required
                  label="Event Type"
                  value={this.state.eventType}
                  onChange={this.handleSelectEventType}
                  helperText="Please select Event Type"
                >
                  {eventTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item required md={true} sm={true} xs={true}>
                <TextField
                  id="responsible-party-select"
                  select
                  required
                  label="Responsible Party"
                  value={this.state.responsibleParty}
                  onChange={this.handleSelectResponsibleParty}
                  helperText="Please select Responsible Party"
                >
                  {responsibleParties.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item required md={true} sm={true} xs={true}>
                <TextField
                  id="lead-facilitator-select"
                  select
                  required
                  label="Lead Facilitator"
                  value={this.state.leadFacilitator}
                  onChange={this.handleSelectLeadFacilitator}
                  helperText="Please select Lead Facilitator"
                >
                  {facilitators.map((facilitator) => (
                    <MenuItem key={facilitator} value={facilitator}>
                      {facilitator}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid container item md={true} sm={true} xs={true}>
                <FormControlLabel
                  value={this.state.hasCoFacilitators}
                  control={<Checkbox color="primary" checked={this.state.hasCoFacilitators} onChange={this.handleHasCoFacilitators}/>}
                  label="Co-Facilitators ?"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            { this.state.hasCoFacilitators &&
              <Grid container spacing={8} alignItems="flex-end">
              <Grid container item md={true} sm={true} xs={true}>
                <FormControl className={classes.formControl}>
                  <InputLabel className={classes.label} id="demo-mutiple-chip-label"></InputLabel>
                  <Select
                    labelId="co-facilitators-label"
                    id="co-facilitator-chip"
                    multiple
                    value={this.state.coFacilitators}
                    onChange={this.handleChangeCoFacilitators}
                    input={<Input id="select-multiple-co-facilitators"/>}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {facilitators.map((facilitator) => (
                      <MenuItem key={facilitator} value={facilitator} style={getStyles(this.state.coFacilitators, facilitator)}>
                        {facilitator}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            }
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="tech-firstname"
                  label="Tech Assistant First Name"
                  type="text"
                  value={this.state.techFirstName}
                  onChange={this.handleTechFirstNameChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="tech-lastname"
                  label="Tech Assistant Last Name"
                  type="text"
                  value={this.state.techLastName}
                  onChange={this.handleTechLastNameChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-start"
                  label="Event Start Date"
                  format="MM/dd/yyyy"
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                />
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-stop"
                  label="Event End Date"
                  format="MM/dd/yyyy"
                  value={this.state.stopDate}
                  onChange={this.handleStopDateChange}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker-start"
                  label="Event Start Time"
                  value={this.state.startTime}
                  onChange={this.handleStartTimeChange}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker-stop"
                  label="Event Stop Time"
                  value={this.state.stopTime}
                  onChange={this.handleStopTimeChange}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="cdp-credits"
                  label="CDP Credits"
                  type="number"
                  value={this.state.cdpCredits}
                  onChange={this.handleCdpCreditsChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="microcredentials"
                  label="Number of Microcredentials"
                  type="number"
                  value={this.state.microCredentials}
                  onChange={this.handleMicroCreditsChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="total-hours"
                  label="Total Hours"
                  type="number"
                  value={this.state.totalHours}
                  onChange={this.handleTotalHoursChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="submission-due-date"
                label="Submission Final Due Date"
                type="datetime-local"
								defaultValue={this.state.submissionFinalDate}
								value={this.state.submissionFinalDate}
                onChange={this.handleFinalSubmissionChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "25px" }}>
              <Grid item md={true} sm={true} xs={true}>    
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: "none" }}
                >
                  Update Event
                </Button>
              </Grid>
            </Grid>
          </div>
        
      </ValidatorForm>
    );
  }
}

export default withStyles(useStyles)(UpdateEvent);