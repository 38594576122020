import './App.css';
import React, { Component } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import NavBar from './components/NavBar'
import CourseList from './components/CourseList'
import AdminDashboard from './components/AdminDashboard'
import FacilitatorDashboard from './components/FacilitatorDashboard'
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { BrowserRouter as Router, Link as RouteLink, Route, Switch, Redirect } from "react-router-dom";
import { browserHistory } from 'react-router';


class App extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      authenticated: false,
      role: '' 
    };

    this.authenticateUser = role => {
      this.setState({authenticated: true,
                      role: role})
    }
  }
 
  render() {
    return (
      <Router>
       {/* <div className="">
          <NavBar authenticated={this.state.authenticated} role={this.state.role}/>
    </div> */}
      <Switch>
        <Route exact path="/">
          {(this.state.authenticated && this.state.role === 'admin') ? 
          <Redirect to="/admin" /> : 
          (this.state.authenticated && this.state.role === 'facilitator') ? 
          <Redirect to="/facilitator" /> : 
          (this.state.authenticated && this.state.role === 'user') ? 
          <Redirect to="/events" /> : 
          <Login authenticateUser={this.authenticateUser}/>}
        </Route>
        <Route exact path="/">
          {(this.state.authenticated && this.state.role === 'facilitator') ? <Redirect to="/facilitator" /> : <Login authenticateUser={this.authenticateUser}/>}
        </Route>
        <Route path='/events' component={CourseList} />
        <Route path='/admin' component={Dashboard} />
        <Route path='/facilitator' component={FacilitatorDashboard} />
      </Switch>
    </Router>
    );
   }
}

export default App;
