import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import PageviewSharpIcon from '@material-ui/icons/PageviewSharp';
import CourseList from './CourseList';
import NewEvent from './NewEvent'

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
}));

export default function AdminDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = React.useState({
    left: false,
    displayAll: false,
    create: false
  });

  const toggleDrawer = (anchor, open, display, create) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }
    console.log(state)
    console.log(open)
    console.log(display)
    console.log(create)
    setState({ ...state, [anchor]: open, displayAll: display, create: create });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List>
        {['Create New Event', 'View Events'].map((text, index) => (
          <ListItem button key={text} onClick={toggleDrawer(anchor, false, (index % 2 === 0 ? false : true), ((index % 2 === 0 ? true : false)))}>
            <ListItemIcon>{index % 2 === 0 ? <CreateSharpIcon /> : <PageviewSharpIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true, state['displayAll'], state['create'])}>Admin Menu</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false, state['displayAll'], state['create'])}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {state.displayAll ? <CourseList /> : (state.create ? <NewEvent /> : '')}
      </main>
    </div>
  );
}